.section {
    background: linear-gradient(rgba(95, 214, 255, 0.8), rgba(0, 152, 218, 0.2));
    width: 100%;
    z-index: 2;
    padding-bottom: 100px;
}

.wrapper {
    background-image: url(./background.svg);
    z-index: 3;
    width: 100vw;
    height: 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
}

.selectorWrapper {
    padding-top: 400px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 90%;
}

.selectorTitle {
    display: flex;
    justify-content: center;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-size: 42px;
}

.text {
    text-indent: 60px;
    font-weight: 100;
    font-size: 24px;
}

.additional {
    width: 100%;
    display: flex;
    justify-content: center;
    text-indent: 30px;
    margin-top: 40px;
    font-size: 16px;
}

.button {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
