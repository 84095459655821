.section {
    display: flex;
    justify-content: center;
    margin-top: 180px;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.title {
    font-size: 4vw;
    font-weight: 100;
    color: #29313E;
}

.text {
    position: absolute;
    text-align: end;
    font-size: 1.3vw;
    font-style: italic;
    font-weight: 100;
    font-family: "SegoeUI-SemilightItalic", "Segoe UI";
    transform: translate(-36vw, 14vw);
}

.text:nth-of-type(2) {
    transform: translate(-38vw, 56vw);
}

.text:nth-of-type(3) {
    transform: translate(13vw, 56vw);
}

.line {
    margin-top: 15px;
    width: 20vw;
    height: 3px;
    background-color: black;
}

.advList {
    position: relative;
    margin-top: 120px;
    height: 55vw;
    max-width: 80vw;
}

.advListTel {
    display: none;
}

.advListBig {
    display: none;
}

@media(max-width: 1100px) {
    .section {
        margin-top: 100px;
    }
}

@media(max-width: 930px) {
    .advList {
        margin-top: 80px;
    }
}

@media(max-width: 800px) {

    .line {
        margin-top: 10px;
        height: 2px;
    }
}

@media(max-width: 700px) {
    .section {
        margin-top: 40px;
    }

    .advList {
        margin-top: 50px;
    }
}

@media (max-width: 500px) {
    .section {
        margin-top: 250px;
    }

    .title {
        font-size: 24px;
    }

    .text {
        font-size: 16px;
        transform: translate(-10px, -1040px);
    }

    .text:nth-of-type(2) {
        transform: translate(0px, -100px);
    }

    .text:nth-of-type(3) {
        transform: translate(-10px, -580px);
    }

    .line {
        width: 116px;
        margin-top: 10px;
        height: 3px;
    }

    .advList {
        display: none;
    }

    .advListTel {
        margin-top: 30px;
        display: flex;
        max-width: 100%;
    }
}

@media(max-width:426px) {
    .section {
        margin-top: 200px;
    }
}

@media(max-width: 376px) {
    .section {
        margin-top: 100px;
    }

    .text {
        font-size: 12px;
        transform: translate(20px, -1040px);
    }

    .text:nth-of-type(2) {
        transform: translate(30px, -100px);
    }

    .text:nth-of-type(3) {
        transform: translate(30px, -580px);
    }
}

@media(min-width: 1650px) {
    .section {
        margin-top: 260px;
    }

    .text {
        transform: translate(-34vw, 10vw);
    }

    .text:nth-of-type(2) {
        transform: translate(-38vw, 50vw);
    }

    .text:nth-of-type(3) {
        transform: translate(13vw, 50vw);
    }
}

@media(min-width: 1960px) {
    .section {
        margin-top: 250px;
    }
}
