.section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20vw;
    background: rgba(0, 152, 218, 0.2);
}

.title {
    margin-top: 80px;
    font-size: 3vw;
    font-weight: 100;
    color: #3f3f3f;
}

.line {
    margin-top: 5px;
    height: 2px;
    width: 15vw;
    background-color: #3f3f3f;
}

.catalog {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.wrapper {
    display: flex;
    justify-content: center;
    -webkit-box-pack: center;
    position: relative;
    width: 33%;
    margin-top: 80px;
}

@media(max-width: 1100px) {
    .wrapper {
        width: 50%;
    }

    .catalog {
        width: 90%;
    }

    .title {
        font-size: 32px;
    }

    .line {
        width: 150px;
    }
}

@media(max-width: 760px) {
    .wrapper {
        width: 100%;
    }
}
