.background {
    position: absolute;
    width: 300px;
    height: 380px;
    background: linear-gradient(rgba(47, 184, 255), rgba(158, 236, 217, 0.8));
    border-radius: 60px 60px 60px 60px;
    transform: matrix(1, -0.14, -0.14, 0.99, 0, 0);
    transform-origin: right bottom;
}

.card {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    width: 100%;
    background: rgba(255, 255, 255, 0.3);
    padding: -50px -20px;
    max-width: 300px;
    height: 379px;
    backdrop-filter: blur(40px);
    border-radius: 45px 45px 45px 45px;
    z-index: 2;
    transition: all 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.card:hover {
    box-shadow: rgb(24 32 79 / 25%) 0px 40px 80px,
        rgb(255 255 255 / 50%) 0px 0px 0px 0.5px inset;
}

.cardWrapper {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.cardTitle {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 600;
    max-width: 95%;
    text-align: center;
    text-transform: uppercase;
}

.cardText {
    margin-top: 60px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
    max-width: 80%;
    font-weight: 500;
}

.cardPrice {
    margin-top: 60px;
    font-size: 42px;
    font-weight: 400;
    color: rgba(0, 0, 0);
}

@media (max-width: 420px) {
    .background {
        width: 200px;
        transform: matrix(1, -0.1, -0.1, 0.99, 0, 0);
    }

    .card {
        max-width: 200px;
    }
}
