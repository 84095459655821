.header, .headerBack {
    position:absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    position: fixed;
    z-index: 10;
    transition: all 0.3s;
}

.headerBack {
    background-color: rgba(109, 210, 253, 0.7);
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    max-width: 70%;
    width: 100%;
}

.logo {
    width: 90px;
    height: 90px;
}

.langSelect {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    font-size: 24px;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}

.langNoSelect {
    display: none;
}

.flag {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

@media(max-width: 800px) {
    .logo {
        width: 60px;
        height: 60px;
    }

    .langSelect {
        font-size: 16px;
    }

    .langSelect {
        margin-bottom: 0;
    }

    .flag {
        width: 15px;
        height: 15px;
    }
}

@media(max-width: 500px) {
    .langSelect {
        position: absolute;
        margin-bottom: 5px;
        right: 30vw;
    }
}

@media(min-width: 1960px) {
    .logo {
        width: 150px;
        height: 150px;
    }

    .langSelect {
        font-size: 32px;
    }

    .flag {
        width: 30px;
        height: 30px;
    }
}
