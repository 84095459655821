.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 9vw;
    margin-bottom: 20vw;
}

.bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 14vw;
    background-color: #7975FC;
}

.leftWrapper {
    margin-left: 10vw;
    font-size: 3vw;
    font-weight: 100;
    color: #FFFFFF;
}

.leftWrapperTel {
    display: none;
}

.rightWrapper {
    display: flex;
    align-items: center;
}

.button {
    display: flex;
    flex-direction: column;
}

.buttonLink {
    margin-bottom: 3vw;
}

.buttonLink:last-of-type {
    margin-bottom: 0;
}

.link {
    text-decoration: none;
}

.aboutImage {
    width: 18vw;
    height: 14vw;
}

.about {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 4.5vw;
}

.titleBox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    align-items: center;
    font-size: 4vw;
    font-weight: 100;
    color: #7976FC;
}

.leftImage {
    position: absolute;
    margin-left: -27vw;
    margin-top: 2vw;
    width: 2.7vw;
    height: 2.7vw;
}

.rightImage {
    position: absolute;
    margin-top: 2vw;
    margin-left: 31vw;
    width: 11.4vw;
    height: 10vw;
}

.text {
    margin-top: 5vw;
    font-size: 1.7vw;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 80vw;
    text-align: center;
    color: #29313E;
}

.questions {
    display: flex;
    justify-content: space-between;
    margin-top: 6vw;
    max-width: 80vw;
    width: 100%;
}

.helpTitle {
    font-size: 2.5vw;
    font-weight: 100;
    color: #7976FC;
}

.helpText {
    margin-top: 1.5vw;
    font-size: 1.2vw;
    color: #29313E;
    max-width: 40vw;
}

.helpBox {
    margin-top: 2.5vw;
    width: 26vw;
    display: flex;
    flex-direction: column;
}

.helpLink {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #7975FC;
    border-radius: 50px;
    width: 26vw;
    height: 3vw;
    box-shadow: 0.7vw 0.7vw 1vw;
    transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}

.helpLink:hover {
    transform: translateY(3px);
}

.helpLinkText {
    color: #FFFFFF;
    font-size: 1.2vw;
    font-weight: 500;
}

.or {
    color: #2C2C34;
    font-size: 1.2vw;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2vw 0;
}

.support {
    width: 32vw;
    height: 28vw;
}

@media(max-width: 500px) {
    .bar {
        height: 33vw;
    }

    .aboutImage {
        width: 43vw;
        height: 33vw;
    }

    .button {
        display: none;
    }

    .leftWrapper {
        display: none;
    }

    .leftWrapperTel {
        display: flex;
        margin-left: 20vw;
        font-size: 24px;
        font-weight: 100;
        color: #FFFFFF;
    }

    .title {
        font-size: 9vw;
    }

    .text {
        font-size: 5vw;
        margin-top: 45px;
    }

    .about {
        margin-top: 75px;
    }

    .leftImage {
        display: none;
    }

    .rightImage {
        display: none;
    }

    .questions {
        margin-top: 12vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 90vw;
    }

    .support {
        margin-top: 10vw;
        width: 90vw;
        height: 77vw;
    }

    .helpTitle {
        display: flex;
        justify-content: center;
        font-size: 5vw;
    }

    .helpText {
        display: flex;
        justify-content: center;
        font-size: 3.2vw;
    }

    .helpBox {
        margin-top: 8vw;
        width: auto;
        align-items: center;
        justify-content: center;
    }

    .helpLink {
        width: 61vw;
        height: 10vw;
    }

    .helpLink:hover {
        width: 70vw;
        height: 12vw;
        margin-top: 5px;
    }

    .helpLinkText {
        font-size: 3.2vw;
    }

    .or {
        font-size: 3.2vw;
    }
}
