.background {
    position: absolute;
    width: 300px;
    height: 380px;
    background: linear-gradient(rgb(243, 160, 122), rgb(243, 230, 122));
    border-radius: 60px 60px 60px 60px;
    transform: matrix(1, -0.14, 0.14, 0.99, 0, 0);
    transform-origin: right bottom;
}

.card {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 280px;
    width: 100%;
    background: rgba(255, 255, 255, 0.3);
    padding: -50px -20px;
    max-width: 300px;
    height: 379px;
    backdrop-filter: blur(40px);
    border-radius: 45px 45px 45px 45px;
    z-index: 2;
    transition: all 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.card:hover {
    box-shadow: rgb(24 32 79 / 25%) 0px 40px 80px,
        rgb(255 255 255 / 50%) 0px 0px 0px 0.5px inset;
}

.cardWrapper {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.cardTitle {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 600;
    max-width: 95%;
    flex-wrap: nowrap;
    text-align: center;
    text-transform: uppercase;
}

.description {
    margin-top: 10px;
    font-weight: 600;
}

.textWrapper {
    margin: 40px 0 0 15px;
    width: 280px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.cardText {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.cardText:last-of-type {
    margin-top: 10px;
}

.text {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
    text-align: left;
    font-weight: 500;
    margin-left: 10px;
}

.cardPrice {
    margin-top: 40px;
    font-size: 42px;
    font-weight: 400;
    color: rgba(0, 0, 0);
}

.img {
    min-width: 25px;
    min-height: 25px;
}

@media (max-width: 420px) {
    .background {
        width: 200px;
        transform: matrix(1, -0.1, -0.1, 0.99, 0, 0);
    }

    .card {
        max-width: 200px;
    }
}
