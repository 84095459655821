body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: 'Montserrat', sans-serif;
}

*, *:before, *:after{
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0;
}

a {
  color: #485fc7;
  text-decoration: none;
}

/* Reset `button` and button-style `input` default styles */
input[type="submit"],
input[type="reset"],
input[type="button"],
button {
    background: none;
    border: 0;
    color: inherit;
    /* cursor: default; */
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    -webkit-appearance: button; /* for input */
    -webkit-user-select: none; /* for button */
       -moz-user-select: none;
        -ms-user-select: none;
}
input::-moz-focus-inner,
button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/* Make `a` like a button */
[role="button"] {
    color: inherit;
    cursor: default;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    white-space: pre;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
}
