.count {
    position: absolute;
    top: 310px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
}

.countHide {
   display: none;
}

.countInput {
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.3;
}

.countInput::-webkit-inner-spin-button,
.countInput::-webkit-outer-spin-button {
    appearance: none;
}

.countControls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    background-color: rgba(255, 240, 245, 0.3);
    width: 140px;
}

.countUp, .countDown {
    outline: none;
    cursor: pointer;
}

.countDown {
    padding-left: 10px;
}

.countUp {
    padding-right: 10px;
}

.countImg {
    width: 15px;
    height: 15px;
}
