.section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 30vw;
    width: 100vw;
    background-color: #2B7EEC;
}

.footer {
    display: flex;
    justify-content: space-between;
    max-width: 80vw;
    width: 100%;
}

.privacy {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5vw;
}

.privacyText {
    color: #FFFFFF;
    font-size: 1.2vw;
}

.privacyLink {
    color: #ffae00;
    font-size: 1.2vw;
}

.block {
    max-width: 30%;
    display: flex;
    flex-direction: column;
}

.logoBlock {
    max-width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button {
    display: flex;
    flex-direction: column;
    margin-top: 1.5vw;
    margin-left: 2vw;
}

.buttonLink {
    margin-bottom: 2vw;
}

.buttonLink:last-of-type {
    margin-bottom: 0;
}

.title {
    font-size: 1.2vw;
    font-weight: 500;
    color: #FFFFFF;
}

.blockLink {
    width: auto;
    font-size: 1vw;
    color: #FFFFFF;
    margin-top: 1.8vw;
}

.blockLink:first-of-type {
    margin-top: 3.5vw;
}

.text {
    margin-top: 3.4vw;
    font-size: 1vw;
    color: #FFFFFF;
}

.logo {
    width: 5vw;
    height: 5vw;
}

.logoText {
    margin-top: 2.6vw;
    text-align: center;
    font-size: 1.2vw;
    color: #FFFFFF;
    font-style: italic;
}

@media(max-width: 500px) {
    .section {
        height: 1300px;
        justify-content: center;
        align-items: space-between;
    }

    .footer {
        max-width: 85vw;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
    }

    .privacy {
        margin-top: 200px;
    }

    .privacyText {
        font-size: 16px;
    }

    .privacyLink {
        font-size: 16px;
    }

    .buttonLink {
        margin-bottom: 30px;
    }

    .buttonLink:last-of-type {
        margin-bottom: 0;
    }

    .block {
        margin-top: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
    }

    .logoBlock {
        max-width: 100%;
    }

    .title {
        font-size: 4.3vw;
    }

    .blockLink {
        margin-top: 25px;
        font-size: 3.7vw;
    }

    .blockLink:first-of-type {
        margin-top: 30px;
    }

    .text {
        margin-top: 35px;
        font-size: 3.7vw;
        text-align: center;
    }

    .logoText {
        margin-top: 40px;
        font-size: 4.3vw;
    }

    .logo {
        width: 16vw;
        height: 16vw;
    }

    .button {
        margin: 30px auto 0 auto;
    }
}
