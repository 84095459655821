.burger {
    position: fixed;
    top: 42px;
    right: 8vw;
    width: 30px;
    height: 20px;
    display: flex;
    z-index: 10;
}

.burgerWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transition: all 0.5s;
    cursor: pointer;
}

.burgerMenu {
    position: absolute;
    width: 320px;
    top: 72px;
    padding: 20px;
    right: -8vw;
    background: rgba(15, 14, 71, 0.3);
    box-shadow: rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
    backdrop-filter: blur(40px) brightness(80%) saturate(150%);
    visibility: hidden;
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    transform-origin: center top;
    transform: translateY(-20px);
}

.burgerMenuActive {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 320px;
    top: 72px;
    padding: 40px;
    right: -8vw;
    background: rgba(56, 195, 254, 0.7);
    backdrop-filter: blur(40px) brightness(80%) saturate(150%);
    visibility: visible;
    opacity: 1;
    transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    transform-origin: center top;
    transform: translateY(0px);
}

.menuTitle {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 32px;
    margin-bottom: 20px;
}

.menuLink {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    text-align: start;
    padding: 20px 0;
    font-size: 24px;
    font-weight: 100;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid black;
}

.menuLink:last-of-type {
    border: none;
}

.burgerLine1 {
    margin-top: 5px;
    height: 3px;
    width: 30px;
    border-radius: 3px;
    background-color: #3f3f3f;
    transition: all 0.3s;
}

.burgerLine1:first-of-type {
    margin-top: 0;
}

.burgerLine2 {
    margin-top: 5px;
    height: 3px;
    width: 20px;
    border-radius: 3px;
    background-color: #3f3f3f;
    transition: all 0.3s;
}

.burgerLine3 {
    margin-top: 5px;
    height: 3px;
    width: 25px;
    border-radius: 3px;
    background-color: #3f3f3f;
    transition: all 0.3s;
}

@media(max-width: 800px) {
    .burger {
        top: 32px;
    }

    .burgerMenu, .burgerMenuActive {
        top: 52px;
    }
}

@media(max-width: 500px) {
    .burger {
        top: 29px;
        right: 48px;
    }

    .burgerMenu, .burgerMenuActive {
        right: -48px;
    }
}

@media(min-width:1960px) {
    .burger {
        top: 72px;
    }

    .burgerMenu, .burgerMenuActive {
        top: 102px;
    }
}
