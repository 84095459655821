.buttonText {
    color: #FFFFFF;
    font-size: 1.5vw;
}

.button {
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15vw;
    height: 3vw;
    background-color: #FFA812;
    border-radius: 50px;
    box-shadow: 5px 5px 5px #545454;
    transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    position: relative;
}

.click {
    position: absolute;
    margin-left: 13.7vw;
    margin-top: 2vw;
    height: 3vw;
    width: 2vw;
    animation: animate 2s linear infinite;
}

.button:hover {
    transform: translateY(3px);
}

@keyframes animate {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(5px);
    }

    100% {
        transform: translateY(0);
    }
}

@media(max-width: 500px) {
    .button {
        width: 206px;
        height: 45px;
    }

    .buttonText {
        font-size: 20px;
    }

    .click {
        margin-left: 185px;
        margin-top: 25px;
        height: 42px;
        width: 35px;
    }
}
