.background {
    width: 100vw;
    position: absolute;
    top: 0;
    z-index: -1;
}

.backgroundTel {
    display: none;
}

.section {
    position: absolute;
    display: flex;
    justify-content: center;
    position: relative;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 70vw;
    width: 100%;
    margin-top: 100px;
}

.leftWrapper {
    width: 50%;
}

.rightWrapper {
    height: 35vw;
    width: 50%;
}

.builder {
    display: none;
}

.link {
    text-decoration: none;
}

.title {
    font-size: 3.5vw;
    font-weight: 100;
    line-height: 1;
    color: #FFFFFF;
}

.text {
    font-size: 1.5vw;
    margin-top: 35px;
    color: #FFFFFF;
}

.coloredText {
    color: #660099;
}

.button {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
}

.builderBig {
    display: none;
}

.catalogButton {
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    border-radius: 10px;
    position: fixed;
    top: 30px;
    transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    background-color: rgba(255, 255, 255, 0.2);
    z-index: 11;
}

.catalogLink {
    margin-left: 5px;
    color: rgba(0, 0, 0, 0.8);
    font-size: 32px;
    font-weight: 100;
}

.catalogImg {
    width: 30px;
    height: 30px;
}

.catalogButton:hover {
    transform: translateY(3px);
    background-color: rgba(0, 0, 0, 0.1);
}

@media(max-width: 1200px) {
    .section {
        margin-top: -10px;
    }

    .background {
        top: 10px;
    }
}

@media(max-width: 1100px) {
    .section {
        margin-top: -30px;
    }

    .background {
        top: 30px;
    }

    .catalogButton {
        top: 35px;
    }

    .catalogLink {
        font-size: 24px;
    }

    .catalogImg {
        width: 25px;
        height: 25px;
    }
}

@media(max-width: 930px) {
    .text {
        margin-top: 20px;
    }

    .button {
        margin-top: 20px;
    }
}

@media(max-width: 800px) {
    .section {
        margin-top: -50px;
    }

    .background {
        top: 50px;
    }

    .text {
        margin-top: 15px;
    }

    .button {
        margin-top: 15px;
    }
    
    .catalogButton {
        top: 25px;
    }

    .catalogLink {
        font-size: 16px;
    }

    .catalogImg {
        width: 15px;
        height: 15px;
    }
}

@media(max-width: 700px) {
    .section {
        margin-top: -50px;
    }
}

@media(max-width: 600px) {
    .text {
        margin-top: 15px;
    }

    .button {
        margin-top: 15px;
    }
}

@media(max-width: 500px) {
    .rightWrapper {
        display: none;
    }

    .button {
        margin-top: 40px;
        flex-direction: column;
    }

    .buttonLink {
        margin-bottom: 40px;
    }

    .buttonLink:last-of-type {
        margin-bottom: 5px;
    }

    .section {
        margin-top: 40px;
    }

    .background {
        display: none;
    }

    .backgroundTel {
        margin-top: -250px;
        display: flex;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: -1;
    }

    .wrapper {
        max-width: 100vw;
        align-items: center;
        flex-direction: column;
    }

    .builder {
        display: flex;
        margin-top: 25px;
    }

    .leftWrapper {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .title {
        font-size: 28px;
        text-align: center;
    }

    .text {
        margin-top: 15px;
        font-size: 14px;
    }

    .buttonLink {
        font-size: 20px;
    }

    .catalogButton {
        padding: 10px 10px;
        top: 15px;
        right: 40px;
    }

    .catalogLink {
        display: none;
    }

    .catalogImg {
        width: 25px;
        height: 25px;
    }
}

@media(max-width: 350px) {
    .section {
        margin-top: 20px;
    }
}

@media(min-width: 1960px) {
    .catalogButton {
        top: 50px;
    }
}
