.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 50px;
}

.title {
    text-align: center;
    margin-bottom: 20px;
}

.textBlock {
    width: 100%;
    max-width: 90vw;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.text {
    color: #4a4a4a;
    font-size: 1em;
    margin-bottom: 1em;
}
