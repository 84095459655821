.background {
    position: absolute;
    width: 300px;
    height: 380px;
    background: linear-gradient(rgba(47, 184, 255), rgba(158, 236, 217, 0.8));
    border-radius: 60px 60px 60px 60px;
    transform: matrix(1, -0.14, -0.14, 0.99, 0, 0);
    transform-origin: right bottom;
    transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.card {
    transform: rotateY(0deg);
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    width: 100%;
    background: rgba(255, 255, 255, 0.3);
    padding: -50px -20px;
    max-width: 300px;
    height: 379px;
    backdrop-filter: blur(40px);
    border-radius: 45px 45px 45px 45px;
    z-index: 2;
    transition: all 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.cardHidden,
.cardBackHidden {
    background: #fff;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transform: rotateY(-180deg);
}

.cardBack {
    top: -120px;
    transform: rotateY(0deg);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-width: 300px;
    width: 100%;
    background: rgba(47, 184, 255, 0.3);
    padding: -50px -20px;
    max-width: 300px;
    height: 379px;
    backdrop-filter: blur(40px);
    border-radius: 45px 45px 45px 45px;
    z-index: 2;
    transition: all 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.card:hover,
.cardBack:hover {
    box-shadow: rgb(24 32 79 / 25%) 0px 40px 80px,
        rgb(255 255 255 / 50%) 0px 0px 0px 0.5px inset;
}

.cardWrapper {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.cardTitle {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 600;
    max-width: 85%;
    text-align: center;
    text-transform: uppercase;
}

.cardText {
    position: relative;
    margin-top: 60px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
    max-width: 100%;
    font-weight: 500;
}

.cardPrice {
    margin-top: 60px;
    font-size: 42px;
    font-weight: 400;
    color: rgba(0, 0, 0);
}

.checkWrapper {
    width: 280px;
    padding-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.checkTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 18px;
    margin-bottom: 15px;
}

.checkTextWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 5px;
}

.checkText {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
    text-align: left;
    font-weight: 500;
    margin-left: 10px;
}

.checkImg {
    min-width: 25px;
    min-height: 25px;
}

.buttonMore {
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    padding: 5px 40px;
    border-radius: 30px;
    color: rgba(0, 0, 0);
    background-color: rgba(255, 240, 245, 0.4);
    transition: all 0.3s;
}

.buttonMore:hover {
    transform: translateY(2px)
}

.leftArrow {
    cursor: pointer;
    position: absolute;
    left: 10px;
    bottom: 11px;
    width: 100px;
    height: 38px;
}

.rightArrow {
    cursor: pointer;
    position: absolute;
    left: 100px;
    bottom: 11px;
    width: 100px;
    height: 38px;
}

.buttonClose {
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 25px;
    height: 25px;
}

.buttonClose:hover {
    animation: animate 0.5s linear;
}

.add {
    width: 100%;
    margin-left: 5px;
    margin-top: 100px;
}

.addText {
    margin-top: 5px;
}

@keyframes animate {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(1px);
    }

    50% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(-1px);
    }

    100% {
        transform: translateY(0);
    }
}

@media (max-width: 420px) {
    .background {
        width: 200px;
        transform: matrix(1, -0.1, -0.1, 0.99, 0, 0);
    }

    .card {
        max-width: 200px;
    }
}
